<template>
  <content-wrapper>
    <object-summary />
    <near-events title="Скоро" />
  </content-wrapper>
</template>

<script>
import ContentWrapper from "@/components/Wrappers/ContentWrapper";
import ObjectSummary from "@/components/Event";
import NearEvents from "@/components/NearEvents";

export default {
  components: {
    ContentWrapper,
    ObjectSummary,
    NearEvents
  }
};
</script>
