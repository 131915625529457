<template>
  <div class="object-full" v-if="selectedEvent">
    <swiper class="photos" :options="{slidesPerView: 'auto', touchRatio: 2}">
      <swiper-slide v-for="(img, index) in selectedEvent.images" :key="index">
        <img :src="img" alt />
      </swiper-slide>
    </swiper>

    <div class="content">
      <div class="left-side">
        <div class="title">{{selectedEvent.title}}</div>
        <div class="imap-info__item">
          <object-item-add :value="$date(this.selectedEvent.started_at, this.selectedEvent.stopped_at, this.selectedEvent.created_at)" icon="date" />
        </div>
        <div class="imap-info__item" v-if="address">
          <object-item-add :value="address" icon="place" />
        </div>
        <object-item-adds class="adds" :adds="selectedEvent.additions" />
      </div>

      <scrolled-content class="right-side">
        <tag :title="selectedEvent.type.name" v-if="selectedEvent.type.name" />
        <div class="text" v-html="$removeLinkTags(selectedEvent.description)"></div>
        <!-- <txt class="text" :content="selectedEvent.description" /> -->
      </scrolled-content>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import ObjectItemAdds from "@/components/Parts/ObjectItemAdds";
import ObjectItemAdd from "@/components/Parts/ObjectItemAdd";
import ScrolledContent from "@/components/Wrappers/ScrolledContent";
import Tag from "@/components/Parts/TagSmall";

export default {
  components: { ObjectItemAdds, ObjectItemAdd, ScrolledContent, Tag }, //, NearObjects },

  computed: {
    ...mapGetters(["selectedEvent", "events"]),

    address() {
      let address;
      if (this.selectedEvent.place) {
        if (this.selectedEvent.place.title)
          address = this.selectedEvent.place.title;
        if (this.selectedEvent.place.address)
          address += ", " + this.selectedEvent.place.address;
      }
      return address;
    }
  },
  watch: {
    $route: "fetchData"
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (!this.selectedEvent || this.selectedEvent.id != this.$route.params.id)
        api.getEvent(this.$route.params.id).then(res => {
          this.$store.dispatch("setSelectedEvent", res.data);
        });
      if (!this.events) {
        this.$store.dispatch("fetchEvents");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.object-full {
  .photos {
    margin-top: 56px;

    .swiper-slide {
      width: 884px;
      height: 442px;

      margin-left: 32px;
      margin-right: 12px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &:last-child {
        margin-right: 32px;
      }
    }
  }

  .content {
    margin-top: 32px;
    display: flex;
    text-align: left;

    .left-side {
      width: 488px;
      margin-left: 32px;
      margin-right: 40px;

      .title {
        width: 488px;
        font-size: 50px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.12;
        letter-spacing: normal;
        color: rgba(#fff, 0.97);
        padding-bottom: 40px;
        border-bottom: 4px solid $color_grey_3;
        margin-bottom: 32px;
      }
    }
    .right-side {
      width: 508px;
      // width: auto;
      max-height: 646px;

      .text {
        margin-top: 32px;
        width: 488px;

        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.1px;
        color: rgba(#fff, 0.8);
        position: relative;
        padding-bottom: 32px;
      }
    }
  }
}
</style>
